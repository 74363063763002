const navItems = [
  {
    icon: 'mdi-home',
    text: 'AnaSayfa',
    route: '/'
  },
  {
    icon: 'mdi-city',
    text: 'Firma',
    route: '/firm'
  },
  {
    icon: 'mdi-trello',
    text: 'Firma Test Raporları',
    route: '/firm-test-reports'
  },
  {
    icon: 'mdi-counter',
    text: 'Kredi',
    route: '/credit'
  },
  {
    icon: 'mdi-alpha-t-box-outline',
    text: 'Test',
    route: '/test'
  },
  {
    icon: 'mdi-clipboard-check-multiple-outline',
    text: 'Sınav',
    route: '/exam'
  },
  {
    icon: 'mdi-alpha-q-box-outline',
    text: 'Soru',
    route: '/question'
  },
  {
    icon: 'mdi-alpha-a-box-outline',
    text: 'Aday',
    route: '/candidate'
  },
  {
    icon: 'mdi-alpha-a-box-outline',
    text: 'Bitmiş Sınavlar',
    route: '/candidate-action-done'
  },
  {
    icon: 'mdi-alpha-a-box-outline',
    text: 'Yarım Kalan Sınavlar',
    route: '/candidate-action'
  },
  {
    icon: 'mdi-trello',
    text: 'Rapor',
    route: '/report'
  }
];

export default navItems;
