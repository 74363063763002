import Vue from 'vue';

// axios
import axios from 'axios';
import router from '@/router';

// const baseURL = 'http://localhost:4101/b1';
const baseURL = 'https://api.taleon.com.tr/b1';

const onError = (response) => {
  if (response?.status === 401) {
    if (localStorage.getItem('token')) {
      localStorage.removeItem('token');
    }
    if (localStorage.getItem('userInfo')) {
      localStorage.removeItem('userInfo');
    }
    router.push({ name: 'login' });
  }
};

const getAxiosInstance = () => {
  const instance = axios.create({ baseURL });

  // Add token to the headers
  instance.interceptors.request.use((config) => {
    const token = localStorage.token;
    if (token) {
      config.headers['x-taleon-backoffice-auth'] = token;
    }
    config.headers['Content-Type'] = 'application/json;charset=UTF-8';
    return config;
  });

  // Redirect to login page on token errors
  instance.interceptors.response.use(
    (response) => {
      onError(response);
      return response;
    },
    (error) => {
      onError(error?.response);
      return Promise.reject(error);
    }
  );

  return instance;
};

Vue.prototype.$http = getAxiosInstance();

export default getAxiosInstance();
