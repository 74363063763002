<template>
  <page-layout>
    <v-row>
      <v-col cols="12" md="12">
        <v-card>
          <v-card-title class="align-start">
            <span class="font-weight-semibold">Genel İstatistikler</span>
            <v-spacer></v-spacer>
            <v-btn icon small class="me-n3 mt-n2">
              <v-icon>
                {{ 'mdi-dots-vertical' }}
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                v-for="data in statisticsData"
                :key="data.title"
                cols="6"
                md="3"
                class="d-flex align-center"
              >
                <v-avatar
                  size="44"
                  :color="data.color"
                  rounded
                  class="elevation-1"
                >
                  <v-icon dark color="white" size="30">
                    {{ data.icon }}
                  </v-icon>
                </v-avatar>
                <div class="ms-3">
                  <p class="text-xs mb-0">
                    {{ data.title }}
                  </p>
                  <h3 class="text-xl font-weight-semibold">
                    {{ data.total }}
                  </h3>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-card>
          <v-card-title class="align-start">
            <span class="font-weight-semibold">Test İstatistikleri</span>
            <v-spacer></v-spacer>
            <v-btn icon small class="me-n3 mt-n2">
              <v-icon>
                {{ 'mdi-dots-vertical' }}
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                v-for="data in report2"
                :key="data.title"
                cols="6"
                md="3"
                class="d-flex align-center"
              >
                <v-avatar
                  size="44"
                  :color="data.color"
                  rounded
                  class="elevation-1"
                >
                  <v-icon dark color="white" size="30">
                    {{ data.icon }}
                  </v-icon>
                </v-avatar>
                <div class="ms-3">
                  <p class="text-xs mb-0">
                    {{ data.title }}
                  </p>
                  <h3 class="text-xl font-weight-semibold">
                    {{ data.total }}
                  </h3>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-card>
          <v-card-title class="align-start">
            <span class="font-weight-semibold">Son Giren Kullanıcılar</span>
          </v-card-title>
          <v-card-text>
            <div>
              <v-data-table
                :headers="headers"
                :items="report"
                :loading="isLoading"
                class="elevation-1 mt-3"
              >
                <template v-slot:[`item.createdAt`]="{ item }">
                  <span>{{ formatDate(item.createdAt) }}</span>
                </template>
              </v-data-table>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/PageLayout';
import axios from '@axios';
import dateformat from 'dateformat';

export default {
  name: 'Home',

  components: {
    PageLayout
  },
  data() {
    return {
      isLoading: true,
      isLoading2: true,
      report: [],
      report2: [],
      headers: [
        {
          text: 'K.Adı',
          value: 'user.username',
          sortable: false
        },
        {
          text: 'İsim',
          value: 'user.name',
          sortable: false
        },
        {
          text: 'Soyisim',
          value: 'user.lastname',
          sortable: false
        },
        {
          text: 'Zaman',
          value: 'createdAt',
          sortable: false
        }
      ],
      statisticsData: [
        {
          icon: 'mdi-account-outline',
          color: 'success',
          title: 'Toplam Aday',
          total: ''
        },
        {
          icon: 'mdi-label-outline',
          color: 'warning',
          title: 'Toplam Test',
          total: ''
        },
        {
          icon: 'mdi-trending-up',
          color: 'primary',
          title: 'Toplam Sınav',
          total: ''
        },
        {
          icon: 'mdi-label-outline',
          color: 'warning',
          title: 'Toplam Firma',
          total: ''
        }
      ]
    };
  },
  mounted() {
    this.list();
    this.list2();
    this.list3();
  },
  methods: {
    formatDate(val) {
      return dateformat(new Date(val), 'dd-mm-yy HH:MM');
    },
    list() {
      axios.get('/overview').then((response) => {
        this.statisticsData[0].total = response.data.totalCandidateCount;
        this.statisticsData[1].total = response.data.totalTestCount;
        this.statisticsData[2].total = response.data.totalExamCount;
        this.statisticsData[3].total = response.data.totalFirmCount;
      });
    },
    list2() {
      axios.get('/last-login-overview').then((response) => {
        this.isLoading = false;
        const items = response.data.result;
        this.report = items;
      });
    },
    list3() {
      axios.get('/test-used-overview').then((response) => {
        this.isLoading2 = false;
        const items = response.data.testOverview;
        this.report2 = items;
      });
    }
  }
};
</script>
