import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'AnaSayfa',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/firm',
    name: 'Firma',
    component: () => import('../views/Firm.vue')
  },
  {
    path: '/firm-test-reports',
    name: 'Firma Test Raporları',
    component: () => import('../views/FirmTestReports.vue')
  },
  {
    path: '/credit',
    name: 'Firma Kredi',
    component: () => import('../views/Contour.vue')
  },
  {
    path: '/test',
    name: 'Test',
    component: () => import('../views/Test.vue')
  },
  {
    path: '/candidate',
    name: 'Aday',
    component: () => import('../views/Candidate.vue')
  },
  {
    path: '/candidate-action-done',
    name: 'Bitmiş Sınavlar',
    component: () => import('../views/CandidateActionDone.vue')
  },
  {
    path: '/candidate-action',
    name: 'Yarım Kalan Sınavlar',
    component: () => import('../views/CandidateAction.vue')
  },
  {
    path: '/exam',
    name: 'Sınav',
    component: () => import('../views/Exam.vue')
  },
  {
    path: '/question',
    name: 'Soru',
    component: () => import('../views/Question.vue')
  },
  {
    path: '/report',
    name: 'Rapor',
    component: () => import('../views/Report.vue')
  },
  {
    path: '/result-taleon-select/:caId',
    name: 'Taleon Select Sonuç',
    component: () => import('../views/ResultBigFive.vue')
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, _from, next) => {
  const token = localStorage.token;

  if (to.name === 'Login' && token) {
    router.push({ path: '/', query: { to: to.path } });
  }

  if (to.name !== 'Login' && !token) {
    router.push({ path: '/login', query: { to: to.path } });
  }

  return next();
});

// For splash screen loading. To be implemented.
router.afterEach(() => {
  const appLoader = document.getElementById('app-loader');
  if (appLoader) {
    appLoader.style.display = 'none';
  }
});

export default router;
